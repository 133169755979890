import React from 'react'
import Layout from "../components/layout"
import Seo from "../components/seo"
import emailjs from "emailjs-com"
import { FaLinkedinIn, FaInstagram, FaTwitter } from "react-icons/fa"

export default function contact() {

    const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs.sendForm('gmail', 'template_u6cdnvf', e.target, 'user_c5PllhblCmi6zg3PcbKLP')
          .then((result) => {
              console.log(result.text);
          }, (error) => {
              console.log(error.text);
          });
          e.target.reset()
      };

    return (
        <Layout>
        <Seo title="contact" description="Heb je vragen of wil je meer weten over de projecten van Jan Willem, neem dan contact op met hem via het contactformulier!" />
        
        <div className="main-content main-contact">
            <h1 className="main-title">Contact</h1>
            <div className="column-left">
                <p className="main-description">
                Ik sta open voor reacties en vragen. Mocht je een opdracht voor mij hebben, aarzel niet.
                </p>
            </div>
            <div className="column-right">
                <form className="contact-formulier" onSubmit={sendEmail}>
                    <input aria-label="name" className="input" type="text" name="name" placeholder="Naam" required />
                    <input aria-label="email" className="input" type="email" name="email" placeholder="Email" required />
                    <input aria-label="subject" className="input" type="text" name="subject" placeholder="Onderwerp" maxlength="30" required />
                    <textarea aria-label="textarea" className="input" rows="5" cols="30" name="message" placeholder="Wat is uw vraag?" maxlength="200" required>
                    </textarea>
                    <button aria-label="send form" className="back-btn" type="submit" name="contact-btn">Verzenden</button>
                </form>

                <div className="social-icons social-contact">
                    <p >Of reik uit op social media!</p>
                    <a href="http://www.linkedin.com/in/janwillemdezeeuw/" target="_blank" rel="noreferrer noopener">
                        <FaLinkedinIn className="social-icon" aria-label="linkedin"/></a>
                    <a href="http://www.instagram.com/jwdezeeuw/" target="_blank" rel="noreferrer noopener"
                        ><FaInstagram className="social-icon" aria-label="instagram"/></a>
                    <a href="http://www.twitter.com/jwdezeeuw" target="_blank" rel="noreferrer noopener"> 
                        <FaTwitter className="social-icon" aria-label="twitter"/></a>
                </div>
            </div>
        </div> 
      </Layout>
    )
}